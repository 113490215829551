import React from 'react';

function PhotoIcon() {
  return (
    <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.769 20.7952C28.2153 20.7952 26.1452 22.8654 26.1452 25.4191C26.1452 27.9728 28.2153 30.0429 30.769 30.0429C33.3227 30.0429 35.3929 27.9728 35.3929 25.4191C35.3929 22.8654 33.3227 20.7952 30.769 20.7952ZM22.9144 25.4191C22.9144 21.0811 26.431 17.5645 30.769 17.5645C35.107 17.5645 38.6237 21.0811 38.6237 25.4191C38.6237 29.7571 35.107 33.2737 30.769 33.2737C26.431 33.2737 22.9144 29.7571 22.9144 25.4191Z"
        fill="#203D5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5298 39.513C21.9761 39.513 19.9059 41.5832 19.9059 44.1369V46.6326C19.9059 47.5247 19.1827 48.2479 18.2905 48.2479C17.3984 48.2479 16.6752 47.5247 16.6752 46.6326V44.1369C16.6752 39.7989 20.1918 36.2822 24.5298 36.2822H37.0083C41.3463 36.2822 44.8629 39.7989 44.8629 44.1369V46.6326C44.8629 47.5247 44.1397 48.2479 43.2475 48.2479C42.3554 48.2479 41.6321 47.5247 41.6321 46.6326V44.1369C41.6321 41.5832 39.562 39.513 37.0083 39.513H24.5298Z"
        fill="#203D5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.769 20.7952C28.2153 20.7952 26.1451 22.8654 26.1451 25.4191C26.1451 27.9728 28.2153 30.0429 30.769 30.0429C33.3227 30.0429 35.3929 27.9728 35.3929 25.4191C35.3929 22.8654 33.3227 20.7952 30.769 20.7952ZM22.9144 25.4191C22.9144 21.0811 26.431 17.5645 30.769 17.5645C35.107 17.5645 38.6236 21.0811 38.6236 25.4191C38.6236 29.7571 35.107 33.2737 30.769 33.2737C26.431 33.2737 22.9144 29.7571 22.9144 25.4191Z"
        fill="#203D5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5298 39.513C21.9761 39.513 19.9059 41.5832 19.9059 44.1369V46.6326C19.9059 47.5247 19.1827 48.2479 18.2906 48.2479C17.3984 48.2479 16.6752 47.5247 16.6752 46.6326V44.1369C16.6752 39.7989 20.1918 36.2822 24.5298 36.2822H37.0083C41.3463 36.2822 44.8629 39.7989 44.8629 44.1369V46.6326C44.8629 47.5247 44.1397 48.2479 43.2475 48.2479C42.3554 48.2479 41.6322 47.5247 41.6322 46.6326V44.1369C41.6322 41.5832 39.562 39.513 37.0083 39.513H24.5298Z"
        fill="#203D5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2991 10.8124C11.4346 10.8124 9.92313 12.3239 9.92313 14.1884V51.6239C9.92313 53.4884 11.4346 54.9999 13.2991 54.9999H48.2389C50.1034 54.9999 51.6149 53.4884 51.6149 51.6239V21.2853C51.6149 20.3931 52.3382 19.6699 53.2303 19.6699C54.1225 19.6699 54.8457 20.3931 54.8457 21.2853V51.6239C54.8457 55.2727 51.8877 58.2307 48.2389 58.2307H13.2991C9.65032 58.2307 6.69236 55.2727 6.69236 51.6239V14.1884C6.69236 10.5396 9.65031 7.58167 13.2991 7.58167H33.2647C34.1569 7.58167 34.8801 8.3049 34.8801 9.19705C34.8801 10.0892 34.1569 10.8124 33.2647 10.8124H13.2991Z"
        fill="#203D5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69231 46.6326C6.69231 45.7404 7.41554 45.0172 8.30769 45.0172H53.2303C54.1224 45.0172 54.8456 45.7404 54.8456 46.6326C54.8456 47.5247 54.1224 48.248 53.2303 48.248H8.30769C7.41554 48.248 6.69231 47.5247 6.69231 46.6326Z"
        fill="#203D5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.8346 6.24242C57.4654 6.87327 57.4654 7.89607 56.8346 8.52692L46.9692 18.3923C46.3383 19.0232 45.3155 19.0232 44.6847 18.3923L38.7654 12.4731C38.1346 11.8422 38.1346 10.8194 38.7654 10.1886C39.3963 9.55773 40.4191 9.55773 41.0499 10.1886L45.8269 14.9656L54.5501 6.24242C55.1809 5.61158 56.2037 5.61158 56.8346 6.24242Z"
        fill="#203D5B"
      />
    </svg>
  );
}

export default PhotoIcon;
