import React from 'react';

function MessageHeartIcon() {
  return (
    <svg viewBox="0 0 53 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51 24.3828C51 36.7445 40.9789 46.7656 28.6172 46.7656C25.7817 46.7656 23.0694 46.2384 20.5729 45.2766C20.1165 45.1008 19.8883 45.0128 19.7068 44.971C19.5283 44.9299 19.3991 44.9127 19.216 44.9057C19.0299 44.8986 18.8257 44.9197 18.4174 44.9619L4.93232 46.3558C3.64664 46.4887 3.0038 46.5552 2.62461 46.3239C2.29432 46.1224 2.06937 45.7859 2.00948 45.4036C1.94073 44.9648 2.24791 44.3962 2.86227 43.2591L2.86229 43.259L7.16942 35.2867C7.52413 34.6302 7.70149 34.3019 7.78182 33.9862C7.86115 33.6745 7.88033 33.4497 7.85495 33.129C7.82926 32.8043 7.6868 32.3817 7.40188 31.5364C6.64475 29.2902 6.23436 26.8844 6.23436 24.3828C6.23436 12.0211 16.2555 2 28.6172 2C40.9789 2 51 12.0211 51 24.3828Z"
        stroke="#213F5F"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6103 17.667C26.2411 14.9314 22.2903 14.1956 19.3219 16.7005C16.3535 19.2055 15.9356 23.3937 18.2667 26.3563C19.7109 28.1918 23.3357 31.5441 25.8849 33.8293C26.8213 34.6687 27.2895 35.0884 27.8514 35.2575C28.3334 35.4025 28.8872 35.4025 29.3692 35.2575C29.931 35.0884 30.3992 34.6687 31.3356 33.8293C33.8848 31.5441 37.5096 28.1918 38.9538 26.3563C41.285 23.3937 40.9181 19.1791 37.8986 16.7005C34.8792 14.2219 30.9795 14.9314 28.6103 17.667Z"
        stroke="#213F5F"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MessageHeartIcon;
